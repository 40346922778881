import React, { FunctionComponent, useState } from "react";
// import { Grid, Collapse, Button } from "@material-ui/core";
import { Grid, Collapse, Button } from "@material-ui/core";
// import { Select, MenuItem, Grid, Collapse, Button } from "@material-ui/core";
import Chart from "react-apexcharts";

import { useStyles } from "../styles";
import { IndicatorProps } from "../MetricsIndicator";
// import AreaChart from "./Chart";
import CharTitle from "../Chart/ChartTitle";
// import { radialBar, bar, area } from "./ChartV2/chartOptions";
import { radialBar, bar, area } from "../ChartV2/chartOptions";
import GeoChart from "../Chart/GeoChart";
// import { numberWithCommas } from "utils/numberFormat";
import { formatDate } from "utils/dateFormat";
import ErrorMessage from "components/Error/ErrorMessage";
import { scrollInto } from "utils/navigate";
import { DemoAreas } from "../Demographics/DemoAreas";
import { getScreenshot } from "utils/getScreenshot";
import DownloadActions from "components/Common/DownloadActions/DownloadActions";
import InstagramGraph from "./chart/InstagramGraph";

interface MetricProps extends IndicatorProps {
  igType?: string;
  igHandler?: (e: any) => void;
  mediaLoading?: boolean;
  igMetadata?: any;
  prevLoading: boolean;
  prevMetrics: any;
  hiddenSubscriber?: boolean;
  igStoriesData?: any;
  dateRange?: {
    startDate: any;
    endDate: any;
  };
}

const getFollowerChartTitle = (platform: string) => {
  switch (platform) {
    case "YouTube":
      return "Subscriber Count";
    case "Apple Music":
      return "Listeners";
    default:
      return "Follower Count";
  }
};

// const getImpressionsChartTitle = (platform: string) => {
//   switch (platform) {
//     case "tikTok":
//       return "Video Views";
//     case "Apple Music":
//       return "Plays";
//     default:
//       return "Total Impressions";
//   }
// };

const MetricV2Instagram: FunctionComponent<MetricProps> = ({
  role,
  metrics: metricsData,
  icon,
  igType,
  igHandler,
  mediaLoading,
  igMetadata,
  url,
  prevLoading,
  prevMetrics,
  hiddenSubscriber,
  dateRange,
}) => {
  // const [totalFollowers, setTotalFollowers] = useState(0);

  const [countriesOpen, showCountries] = useState(false);
  const [citiesOpen, showCities] = useState(false);
  const totalFollowers =
    metricsData?.followers[metricsData?.followers?.length - 1] ?? 0;

  // const [dateList, setDateList] = useState(null);

  let metrics = metricsData;
  const midpoint = Math.ceil(metricsData.dates.length / 2);
  let metricsDates = metricsData.dates.slice(midpoint);
  metrics.dates = metricsDates;

  metrics.dates = metricsDates;

  const followers = {
    series: [
      {
        name: `${role === "Apple Music" ? "Listeners" : "Followers"}`,
        data: metrics.followers,
      },
    ],
  };
  const impressions = {
    series: [
      {
        name: "Impressions",
        data: metrics.impressions || [],
      },
    ],
  };

  const reachs = {
    series: [
      {
        name: "Reach",
        data: metrics.reachs || [],
      },
    ],
  };

  // const interactions = {
  //   series: [
  //     {
  //       name: "Total Interactions",
  //       data: (metricsDates && metrics.interactions) || [],
  //     },
  //   ],
  // };
  const profileViews = {
    series: [
      {
        name: "Total Profile Views",
        data: (metricsDates && metrics.profileViews) || [],
      },
    ],
  };

  const dateLength = metricsDates?.length;

  const classes = useStyles({});
  const [screenWidth] = useState(
    window.matchMedia("(min-width: 1320px)").matches
  );

  const areaOptions = {
    ...area.options,
    markers:
      dateLength > 30
        ? {}
        : {
            size: 5,
            colors: ["white"],
            strokeColor: "#5187E0",
            strokeWidth: 2,
          },
    xaxis: {
      categories: dateLength > 30 ? metricsDates : formatDate(metrics.dates),
      type: dateLength > 30 ? "datetime" : "date",
      labels: {
        datetimeUTC: false,
      },
    },
  };

  const generateScreenshot = () => {
    const chart: any = document.getElementById("city-country");
    chart.style.border = "solid 1px #D8D8D8";
    getScreenshot(
      "city-country",
      `${role[0].toUpperCase() + role.slice(1)}_audience_by_city_country`
    );

    chart.style.border = "none";
  };

  return (
    <div style={{ marginBottom: "45px" }}>
      {metrics.dates.length > 0 && (
        <>
          <Grid className={classes.metrics} id="metrics">
            {dateRange &&
              metricsDates &&
              igType !== "stories" &&
              role === "instagram" &&
              !hiddenSubscriber && (
                <InstagramGraph
                  metrics={{ ...followers, areaOptions }}
                  dateGraph={metricsDates}
                  key="follower-count"
                  icon={icon}
                  role={role}
                  name={getFollowerChartTitle(role)}
                  url={url}
                  prevLoading={prevLoading}
                  prevData={prevMetrics}
                  dateRange={dateRange}
                  metricsData={metricsData}
                />
              )}
            {/* {dateRange &&
              metricsDates &&
              !!impressions?.series[0]?.data?.length && (
                <InstagramGraph
                  metrics={{ ...impressions, areaOptions }}
                  dateGraph={metricsDates}
                  key="total-impressions"
                  icon={icon}
                  role={role}
                  name={getImpressionsChartTitle(role)}
                  url={url}
                  prevLoading={prevLoading}
                  prevData={prevMetrics}
                  dateRange={dateRange}
                  metricsData={metricsData}
                />
              )} */}
            {/* 
            {dateRange &&
              metricsDates &&
              !!interactions?.series[0]?.data?.length && (
                <InstagramGraph
                  metrics={{ ...interactions, areaOptions }}
                  dateGraph={metricsDates}
                  key="Total Interactions"
                  icon={icon}
                  role={role}
                  name="Total Interactions"
                  url={url}
                  prevLoading={prevLoading}
                  prevData={prevMetrics}
                  dateRange={dateRange}
                  metricsData={metricsData}
                />
              )} */}
            {dateRange &&
              metricsDates &&
              !!profileViews?.series[0]?.data?.length && (
                <InstagramGraph
                  metrics={{ ...profileViews, areaOptions }}
                  dateGraph={metricsDates}
                  key="Total Profile Views"
                  icon={icon}
                  role={role}
                  name="Total Profile Views"
                  url={url}
                  prevLoading={prevLoading}
                  prevData={prevMetrics}
                  dateRange={dateRange}
                  metricsData={metricsData}
                />
              )}
            {dateRange && metricsDates && !!reachs?.series[0]?.data?.length && (
              <InstagramGraph
                metrics={{ ...reachs, areaOptions }}
                dateGraph={metricsDates}
                key="Reach"
                icon={icon}
                role={role}
                name="Total Reach"
                url={url}
                prevLoading={prevLoading}
                prevData={prevMetrics}
                dateRange={dateRange}
                metricsData={metricsData}
              />
            )}
            {dateRange && metricsDates && !!reachs?.series[0]?.data?.length && (
              <InstagramGraph
                metrics={{ ...impressions, areaOptions }}
                dateGraph={metricsDates}
                key="Impressions"
                icon={icon}
                role={role}
                name="Total Impressions"
                url={url}
                prevLoading={prevLoading}
                prevData={prevMetrics}
                dateRange={dateRange}
                metricsData={metricsData}
              />
            )}
          </Grid>
          {role !== "twitter" &&
            role !== "tikTok" &&
            role !== "YouTube" &&
            igType !== "stories" && (
              <>
                {metrics.followers[0] >= 100 ? (
                  <>
                    <Grid
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 2fr",
                        gridGap: "36.5px",
                      }}
                      id="age-gender"
                    >
                      {metrics.audienceGender &&
                        metrics.audienceGender?.some(
                          (element) => !isNaN(element)
                        ) && (
                          <div id={`${role}-Audience by Gender`}>
                            <Grid key={"audience-by-gender"}>
                              <CharTitle
                                icon={icon}
                                role={role}
                                name={`${
                                  role === "Spotify" ? "Listeners" : "Audience"
                                } by Gender`}
                                url={url}
                              />
                              <div
                                className={classes.chartContainer}
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <Chart
                                  options={radialBar.options}
                                  series={metrics.audienceGender}
                                  type="radialBar"
                                  height={315}
                                  width={312}
                                />
                              </div>
                            </Grid>
                          </div>
                        )}
                      {metrics.audienceAge &&
                        metrics.audienceAge?.some((element: any) =>
                          element?.data?.some((val) => Number(val) > 0)
                        ) && (
                          <div id={`${role}-Audience by Age`}>
                            <Grid key={"audience-by-age"}>
                              <CharTitle
                                icon={icon}
                                role={role}
                                name={`${
                                  role === "Spotify" ? "Listeners" : "Audience"
                                } by Age`}
                                url={url}
                              />
                              <div className={classes.chartContainer}>
                                <Chart
                                  options={bar.options}
                                  series={metrics.audienceAge}
                                  type="bar"
                                  height={315}
                                />
                              </div>
                            </Grid>
                          </div>
                        )}
                    </Grid>
                    {!!metrics?.audienceCity?.length &&
                      !!metrics?.audienceCountry?.length && (
                        <div id={`${role}-Audience by City/Country`}>
                          <CharTitle
                            icon={icon}
                            role={role}
                            name={`${
                              role === "Spotify" ? "Listeners" : "Audience"
                            } by City/Country`}
                            url={url}
                          />
                          <div
                            className={classes.chartContainer}
                            style={{ display: "flex", height: "auto" }}
                          >
                            <DownloadActions
                              generateScreenshot={generateScreenshot}
                              color="#6E8192"
                            />
                            <Grid
                              className={classes.cityCountry}
                              id="city-country"
                            >
                              {metrics.audienceCountry && (
                                <GeoChart audience={metrics.audienceCountry} />
                              )}
                              <div style={{ display: "flex" }}>
                                {screenWidth && (
                                  <div className={classes.mapSeparator}></div>
                                )}
                                <div
                                  className={classes.top5Locations}
                                  id="top5"
                                >
                                  <div id={role}>
                                    <DemoAreas
                                      metrics={{
                                        areas: metrics.countries?.topCountries,
                                        max: totalFollowers,
                                      }}
                                      type={"country"}
                                    />
                                    <Collapse
                                      in={countriesOpen}
                                      timeout="auto"
                                      unmountOnExit
                                    >
                                      <DemoAreas
                                        metrics={{
                                          areas:
                                            metrics.countries?.otherCountries,
                                          max: totalFollowers,
                                        }}
                                        type={"country"}
                                      />
                                    </Collapse>
                                    {!!metrics.countries?.topCountries
                                      ?.length && (
                                      <Button
                                        onClick={() => {
                                          showCountries(!countriesOpen);
                                          countriesOpen && scrollInto(role);
                                        }}
                                        className={classes.showMore}
                                      >
                                        {countriesOpen
                                          ? "See Less"
                                          : "See More"}
                                      </Button>
                                    )}
                                  </div>
                                  <div>
                                    <DemoAreas
                                      metrics={{
                                        areas: metrics.cities?.topCities,
                                        max: totalFollowers,
                                      }}
                                      type={"city"}
                                    />
                                    <Collapse
                                      in={citiesOpen}
                                      timeout="auto"
                                      unmountOnExit
                                    >
                                      <DemoAreas
                                        metrics={{
                                          areas: metrics.cities?.otherCities,
                                          max: totalFollowers,
                                        }}
                                        type={"city"}
                                      />
                                    </Collapse>
                                    {!!metrics.cities?.topCities?.length && (
                                      <Button
                                        onClick={() => {
                                          showCities(!citiesOpen);
                                          citiesOpen && scrollInto(role);
                                        }}
                                        className={classes.showMore}
                                      >
                                        {citiesOpen ? "See Less" : "See More"}
                                      </Button>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </Grid>
                          </div>
                        </div>
                      )}
                  </>
                ) : (
                  <ErrorMessage message="No demographics data available. Probably due to less than 100 followers." />
                )}
              </>
            )}
        </>
      )}
    </div>
  );
};

const areEqual = (prevProps, nextProps): any => {
  if (prevProps.metrics === nextProps.metrics) {
    return true;
  }
};

export default React.memo(MetricV2Instagram, areEqual);
